import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'
import { flexBreakpoint } from './styles'

const getFlexBasis = (margin) => `calc(50% - ${margin} / 2)`

const styles = (theme) => {
  return createStyles({
    item: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        flexBasis: getFlexBasis('4.1875rem'),
      },
      [theme.breakpoints.up('lg')]: {
        flexBasis: getFlexBasis('4.6875rem'),
      },
    },
  })
}

const Item = ({ classes, children }) => (
  <div className={classes.item}>{children}</div>
)

export default withStyles(styles)(Item)
