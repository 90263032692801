import React from 'react'
import Head from 'react-helmet'

import Hero from './Hero'
import Description from './Description/index'
import Features from './Features'
import StoryWithName from './StoryWithName'
import Layout from '../components/Layout'

const Mission = () => {
  return (
    <Layout>
      <Head>
        <title>About</title>
      </Head>
      <Hero />
    </Layout>
  )
}

export default Mission
