import React from 'react'
import {
  Container,
  Typography,
  createStyles,
  withStyles,
} from '@material-ui/core'

import image from './story-with-name.png'

const styles = (theme) => {
  const flexBreakpoint = 'md'
  return createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '45.25rem',
      paddingLeft: '1.6875rem',
      paddingRight: '1.3125rem',
      [theme.breakpoints.up('md')]: {
        padding: 0,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '57.375rem',
      },
    },
    image: {
      width: '12.5rem',
      marginBottom: '2rem',
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'none',
      },
    },
    title: {
      marginBottom: '2.4375rem',
    },
    bodyAndImage: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'flex',
        alignItems: 'center',
      },
    },
    body: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        marginRight: '3.5rem',
        width: '23rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: '7.5625rem',
        width: '31.0625rem',
      },
    },
    imageColumn: {
      display: 'none',
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'block',
        width: '18.75rem',
      },
    },
  })
}

const Image = (props) => (
  <img
    {...props}
    src={image}
    alt='Two people holding a sign saying, "I love Avocado Finance"'
  />
)

const StoryWithName = ({ classes }) => {
  return (
    <section>
      <Container className={classes.content}>
        <Image className={classes.image} />
        <div>
          <Typography className={classes.title} variant="h3" align="center">
            What’s the story with your name?
          </Typography>
          <div className={classes.bodyAndImage}>
            <Typography className={classes.body}>
              Our company was made so people can spend smarter. We think paying
              a little extra for Avocados are worth it, just like paying a
              little extra for subscribing to Avocado Finance.
            </Typography>
            <Image className={classes.imageColumn} />
          </div>
        </div>
      </Container>
    </section>
  )
}

export default withStyles(styles)(StoryWithName)
