import { createStyles, Typography, withStyles } from '@material-ui/core'

const styles = () => {
  return createStyles({
    text: {
      color: '#FF0080',
    },
  })
}

export default withStyles(styles)(Typography)
