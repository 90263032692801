import { TableCell, createStyles, withStyles } from '@material-ui/core'
import { border } from './styles'

const styles = (theme) => {
  return createStyles({
    root: {
      maxWidth: '5.125rem',
      padding: '2.5rem .5rem',
      borderBottom: border,
      '&:first-of-type': {
        maxWidth: '100%',
        width: '21.8125rem',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '2.5625rem 1.1875rem',
      },
    },
  })
}

export default withStyles(styles)(TableCell)
