import React from 'react'
import { createStyles, withStyles } from '@material-ui/core'

import Text from '../Text'
import Cell from '../Cell'
import checkmarkIcon from './checkmark.png'
import { border } from '../styles'

const styles = () => {
  return createStyles({
    item: {
      borderBottom: border,
    },
    icon: {
      width: '1.1875rem',
      height: '1.4375rem',
    },
  })
}

const Item = ({ classes, isChecked, ...other }) => {
  return (
    <Cell className={classes.item} align="center" {...other}>
      {isChecked ? (
        <img src={checkmarkIcon} alt="Checked" className={classes.icon} />
      ) : (
        <Text>-</Text>
      )}
    </Cell>
  )
}

export default withStyles(styles)(Item)
