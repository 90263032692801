import React from 'react'
import {
  TableContainer,
  TableBody,
  TableHead,
  Table,
  Container,
  createStyles,
  withStyles,
  Typography,
  Paper,
} from '@material-ui/core'

import Row from './Row'
import ComparisonRow from './ComparisonRow'
import logo from '../../images/logo.png'
import { border } from './styles'
import Cell from './Cell'

const styles = (theme) => {
  return createStyles({
    section: {
      marginBottom: '5.1875rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '14.875rem',
      },
    },
    tableContainer: {
      border,
      borderRadius: '0.875rem',
      margin: '0 auto',
      maxWidth: '48.3125rem',
      boxShadow: 'none',
    },
    table: {
      width: '100%',
    },
    head: {
      fontSize: '0.625rem',
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontSize: '1.125rem',
        textAlign: 'center',
        maxWidth: '13.875rem',
      },
    },
    logo: {
      width: '5rem',
      [theme.breakpoints.up('md')]: {
        width: '8.4375rem',
      },
    },
    title: {
      marginBottom: '1.6875rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '4.3125rem',
        lineHeight: '3.06rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '4.375rem',
        lineHeight: '3.24rem',
      },
    },
  })
}

const Features = ({ classes }) => {
  return (
    <section className={classes.section}>
      <Container>
        <Typography className={classes.title} variant="h2" align="center">
          Our Approach
        </Typography>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table className={classes.table}>
            <TableHead>
              <Row>
                <Cell />
                <Cell align="center">
                  <img
                    src={logo}
                    alt="Avocado Finance"
                    className={classes.logo}
                  />
                </Cell>
                <Cell align="center">
                  <Typography className={classes.head}>
                    Other Applications
                  </Typography>
                </Cell>
              </Row>
            </TableHead>
            <TableBody>
              <ComparisonRow
                label="Personal finance forecasting"
                avocadoFinance
              />
              <ComparisonRow label="Historical budget tracking" otherApps />
              <ComparisonRow
                label="Financial future visibility"
                avocadoFinance
              />
              <ComparisonRow
                label="Connected to banks"
                avocadoFinance
                otherApps
              />
              <ComparisonRow
                label="Practical financial insights"
                avocadoFinance
              />
              <ComparisonRow
                label="Ads of unnecessary financial products"
                otherApps
              />
              <ComparisonRow
                label="Pushes own investments product for a fee"
                otherApps
              />
              <ComparisonRow label="Does not sell user data" avocadoFinance />
              <ComparisonRow label="Created for every user" avocadoFinance />
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </section>
  )
}

export default withStyles(styles)(Features)
