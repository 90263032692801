import React, { useEffect, useState } from 'react'
import {
  Typography,
  Container,
  createStyles,
  withStyles,
  Button,
} from '@material-ui/core'

import { Link } from 'gatsby'

import { getHeroStyles } from '../../styles/hero'
import icon from './mission.png'
import { flexBreakpoint } from './styles'
import { getContentContainerStyles } from '../ContentContainer'


const styles = (theme) => {
  const paddingX = '1.25rem'
  const heroStyles = getHeroStyles()
  const containerStyles = getContentContainerStyles(theme)

  const titleLineHeightMd = '3.06rem'
  const titleFontSizeMd = '2rem'

  const titleExtraLineHeight = `${titleLineHeightMd} - ${titleFontSizeMd}`
  const titleExtraVerticalLineHeight = `(${titleExtraLineHeight}) / 2`

  const getTitleIconHeight = (height) =>
    `calc(${height} - (${titleExtraLineHeight}))`

  return createStyles({
    hero: {
      ...heroStyles,
      marginBottom: '6rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '7.1875rem',
      },
    },
    container: {
      paddingLeft: '2.0625rem',
      paddingRight: '1.875rem',
      ...containerStyles,
    },
    subtitleContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2.5rem',
      justifyContent: 'center',
    },
    subtitleText: {
      fontSize: '26px',
      fontWeight: 800,
      color: '#F0EDEF',
      textAlign: 'center',
      marginBottom: 120,

      [theme.breakpoints.up(flexBreakpoint)]: {
        fontSize: '52px',
        marginBottom: 280,
      },
    },
    subtitleIcon: {
      width: '1.25rem',
      height: '1.6169rem',
      marginRight: '1rem',
    },
    description: {
      fontSize: '18px',
      fontWeight: 400,
      color: '#F0EDEF',
      textAlign: 'left',
      marginBottom: 25,

      [theme.breakpoints.up(flexBreakpoint)]: {
        fontSize: '24px',
        marginBottom: 65,
      },
    },
    titleContainer: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'flex',
      },
    },
    titleIcon: {
      display: 'none',
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'block',
        height: getTitleIconHeight('12rem'),
      },
      [theme.breakpoints.up('lg')]: {
        height: getTitleIconHeight('6rem'),
      },
    },
    title: {
      textAlign: 'center',
      lineHeight: '2.16rem',
      width: '100%',
      fontSize: '24px',
      fontWeight: 600,
      marginBottom: 40,

      [theme.breakpoints.up(flexBreakpoint)]: {
        lineHeight: titleLineHeightMd,
        fontSize: '36px',
        fontWeight: 600,
        marginBottom: 50,
        marginTop: `calc(-1 * (${titleExtraVerticalLineHeight}))`,
      },
    },
    btns: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.up('xs')]: {
        alignItems: 'center',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',

        marginTop: '1rem',
      },
      [theme.breakpoints.up('lg')]: {
        flexDirection: 'row',
        marginTop: '1.5rem',
      },
    },
    link: {
      textDecoration: "none",
      color: "#fff",
      marginTop: "15px"
    },
    button: {
      [theme.breakpoints.up('lg')]: {
        borderRadius: '6.25rem',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        textAlign: 'center',
        marginLeft: '0.75rem',
        boxShadow: 'none',
        paddingLeft: paddingX,
        paddingRight: paddingX
      },
      [theme.breakpoints.up('sm')]: {
        borderRadius: '6.25rem',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        textAlign: 'center',
        marginLeft: '0.75rem',
        boxShadow: 'none',
        paddingLeft: paddingX,
        paddingRight: paddingX,
      },
      borderRadius: '6.25rem',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: '17px',
      textAlign: 'center',
      // marginLeft: '0.75rem',
      boxShadow: 'none',
      paddingLeft: paddingX,
      paddingRight: paddingX,
      width: 192,
      height: "40px",
      marginTop: "0px"
    },
  })
}

const HeroImage = (props) => <img src={icon} alt="mission" {...props} />

const Hero = ({ classes }) => {
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(window.innerWidth <= 599)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setIsPhone(window.innerWidth <= 599)
  }

  return (
    <section className={classes.hero}>
      <Container className={classes.container}>
        <Typography className={classes.title}>
          Mission
        </Typography>
        <Typography className={classes.subtitleText}>
          Help people take control of their finances and achieve their financial goals
        </Typography>
        <Typography className={classes.title}>
          Our Principles
        </Typography>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}>
          <Typography className={classes.description}>
            Budgeting your money is good practice. But when it comes to improving your financial future, it’s not everything.
          </Typography>
          <Typography className={classes.description}>
            Having a strong income is a start, but it’s actually the choices you make with your money that truly make the difference.
          </Typography>
          <Typography className={classes.description}>
            Your financial future is determined by the choices you make with your money. Are you saving? Investing? Paying off bad debt?
          </Typography>
          <Typography className={classes.description}>
            Avocado Finance empowers you to make choices that will help you and your financial life, in the long run.
          </Typography>
          <Typography className={classes.description}>
            Managing your finances better today will result in earlier financial freedom for you, and we aim to provide the tools to make this happen.
          </Typography>
          <Typography className={classes.description}>
            Download the Avocado Finance App and see if you are on path to achieving your financial goals.
          </Typography>
        </div>

        <div className={classes.btns}>
          <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : '/get-app'} className={classes.link}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
            >Sign Up
            </Button>
          </Link>
          <Link to="/features" className={classes.link}>
            <Button
              className={classes.button}
              variant="outlined"
              color="inherit"
            >Browse Features
            </Button>
          </Link>
        </div>
      </Container>
    </section>
  )
}

export default withStyles(styles)(Hero)
