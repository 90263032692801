import React from 'react'
import {
  Typography,
  Container,
  createStyles,
  withStyles,
} from '@material-ui/core'

import Item from './Item'
import { flexBreakpoint } from './styles'
import { getContentContainerStyles } from '../ContentContainer'

const styles = (theme) => {
  const contentContainerStyles = getContentContainerStyles(theme)
  return createStyles({
    section: {
      marginBottom: '6rem',
      [theme.breakpoints.up('md')]: {
        marginBottom: '10.5rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '10.3125',
      },
    },
    container: {
      paddingLeft: '2.3125rem',
      paddingRight: '3.125rem',
      ...contentContainerStyles,
    },
    items: {
      [theme.breakpoints.up(flexBreakpoint)]: {
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
  })
}

const Description = ({ classes }) => {
  return (
    <section className={classes.section}>
      <Container className={classes.container}>
        <div className={classes.items}>
          <Item>
            <Typography paragraph>
              At Avocado Finance, we believe that Personal Finance Forecasting is the only way to truly remove financial uncertainty out of people’s lives.
            </Typography>
            <Typography paragraph>
              Other apps focus on historical budgeting and show you what you spent at Nordstrom last month. While that all has its perks, it doesn’t actually help you plan ahead, especially for the long-term life events.
            </Typography>
          </Item>
          <Item>
            <Typography paragraph>
              Unlike other apps, Avocado Finance only focuses on what is current and what is to come. We don’t focus on the past. We strive to be the tool that you can use to effortlessly plan ahead.
            </Typography>
            <Typography paragraph>
              We’re a team of startup veterans with finance and engineering experience at some of the best finance and technology companies in the world.
            </Typography>
          </Item>
        </div>
      </Container>
    </section>
  )
}

export default withStyles(styles)(Description)
