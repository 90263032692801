import React from 'react'

import Item from './Item'
import Row from './Row'
import Cell from './Cell'

const ComparisonRow = ({ label, avocadoFinance, otherApps, ...other }) => {
  return (
    <Row {...other}>
      <Cell variant="head">{label}</Cell>
      <Item isChecked={avocadoFinance} />
      <Item isChecked={otherApps} />
    </Row>
  )
}

export default ComparisonRow
