import React from 'react'
import { createStyles, TableRow, withStyles } from '@material-ui/core'

const styles = () => {
  return createStyles({
    row: {
      '&:last-of-type': {
        '& td': {
          borderBottom: '0 !important',
        },
      },
    },
  })
}

const Row = ({ classes, ...other }) => (
  <TableRow className={classes.row} {...other} />
)

export default withStyles(styles)(Row)
