import { getContainerStyles } from '../components/ContentContainer'

export const getContentContainerStyles = (theme) => {
  const { container: baseContainerStyles } = getContainerStyles(theme)
  const breakpointMd = theme.breakpoints.up('md')
  return {
    ...baseContainerStyles,
    [breakpointMd]: {
      ...baseContainerStyles[breakpointMd],
      padding: 0,
    },
  }
}
